import react, { useEffect, useState, useRef } from 'react'
import axios from 'axios';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import ArrowLeftSide from './../svgs/arrowLeftSide'


let words = ["Sit down", "Stand up", "Write"]
const baseurl = process.env.REACT_APP_FRONT_LINK;

function DragAndDrop(props) {

    const history = useHistory();

    const intr = useRef(null);
    const audioPlayer = useRef(null)

    const [locations, setLocations] = useState([]);
    const [isPlaying, setIsPlaying] = useState("FirstTime");
    const [isSlow, setIsSlow] = useState(false);

    const [data, setData] = useState({
        audio: "assets/tracks/t06/t06.mp3",
        mainTitle: "What’s your name?",
        mainTitleColor: "#89C647",
        speechs: []
    });
    const arrayMoveMutable = (array, fromIndex, toIndex) => {
        const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;

        if (startIndex >= 0 && startIndex < array.length) {
            const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;

            const [item] = array.splice(fromIndex, 1);
            array.splice(endIndex, 0, item);
        }
        return array;
    }
    const SortableItem = SortableElement(({ value, index2 }) => <div style={{ position: "relative" }} className="blue-border"><div
        style={

            {
                position: "absolute",
                background: "white",
                color: "#0B329D",
                top: -12,
                left: -5,
                width: 30,
                height: 30,
                borderRadius: "100%",
                fontFamily: 'fredokaone',
                fontWeight: "normal",
                fontSize: 20,
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }
        }>{index2 + 1}</div><img src={value.img} /></div>);

    const SortableList = SortableContainer(({ items }) => {
        return (
            <div className="sort-item" style={{ display: "flex", alignItems: "center", flexDirection: "row", margin: "auto", width: "100%", maxWidth: 1000 }}>
                {items.map((value, index) => (
                    <SortableItem key={`item-${value}`} index={index} index2={index} value={value} />
                ))}
            </div>
        );
    });




    const [randList, setRandList] = useState([]);


    const shuffle = (array) => {
        let currentIndex = array.length, randomIndex;

        // While there remain elements to shuffle...
        while (currentIndex != 0) {

            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }

        return array;
    }
    const checkQuestion = () => {
        let answerFormat = {
            pass: true,
            progress: 0,
            questionTitle: props.questions.title,
            userAnswers: [],
            type: "listen-read"
        }
        props.nextExercise(answerFormat);
        //history.push(baseurl + "complete"); 
    }

    useEffect(() => {

        setData(props.questions)
        intr.current.play();

        let current_locations = []
        props.questions.speechs.forEach((mainItem, m) => {
            mainItem.locations.forEach((item, i) => {
                item.lines.forEach((lineItem, o) => {
                    lineItem.spans.forEach((subItem, j) => {
                        subItem.id = `item-${m}-${i}-${o}-${j}`;
                        current_locations.push(subItem);

                    });
                });
                // item.spans.forEach((subItem,j) => {
                //     subItem.id = `item-${m}-${i}-${j}`;
                //     current_locations.push(subItem);
                // })
            })
        });

        // console.log(current_locations);


        setLocations(current_locations);




        // setTimeout(() => {
        // intr.current.play();
        // },300)

    }, [])

    useEffect(() => {

        setTimeout(() => {

            audioPlayer.current.addEventListener("ended", (event) => {
                if (document.getElementsByClassName("word-highlight").length > 0) {
                    document.getElementsByClassName("word-highlight")[0].classList.remove("word-highlight")
                }
                setIsPlaying(false);
            });
            audioPlayer.current.addEventListener("timeupdate", (event) => {
                let currentTime = audioPlayer.current.currentTime;
                let cure = [...locations]
                let find = locations.findIndex((item) => {
                    let findv2 = false;

                    if (item.end >= currentTime) {
                        findv2 = true;
                    }

                    return findv2 === true;

                });
                if (find !== -1) {
                    if (document.getElementsByClassName("word-highlight").length > 0) {
                        document.getElementsByClassName("word-highlight")[0].classList.remove("word-highlight")
                    }
                    document.getElementById(locations[find].id).classList.add("word-highlight");
                }
            });
        }, 200);

    }, [audioPlayer, locations])

    const playIntr = (e) => {
        // audioPlayer.current.currentTime = 5.5;
        audioPlayer.current.play();
        setIsPlaying(true);
    }
    const pause = (e) => {
        audioPlayer.current.pause();
        setIsPlaying(false);
    }

    const reducePace = () => {
        if (audioPlayer.current) {
            audioPlayer.current.playbackRate =
                audioPlayer.current.playbackRate === 1 ? 0.7 : 1;
            setIsSlow(!isSlow);
        }
    };

    return (<div style={{ width: "70%" }}>
        <div className="question-title-container">
            <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                <img src={baseurl + "assets/game5/listen.svg"} />
                <h1 className="question-title">{props.questions.newTitle === true ? props.questions.title : "Listen and read."}</h1>

            </div>
            <div>
                {props.questions.removeAudio !== true &&
                    <div style={{ display: "flex", gap: "25px" }}>
                        {!isPlaying || isPlaying === "FirstTime" ?
                            <div id="audio-bt" title='Play' onClick={playIntr}><img src={baseurl + "assets/audio/play.png"} /></div> :
                            <div id="audio-bt" title='Pause' onClick={pause}><img src={baseurl + "assets/audio/pause.png"} /></div>
                        }
                        <div id="audio-bt" title={isSlow ? "Reset speed to normal" : "Reduce speed"}
                            onClick={reducePace}><img src={isSlow ? baseurl + "assets/audio/playback-speed-active.png" : baseurl + "assets/audio/playback-speed-inactive.png"} /></div>
                    </div>
                }
            </div>

        </div>





        <audio src={props.questions.newAudio === true ? `${baseurl}${props.questions.instr}` : `${baseurl}assets/tracks/t06/track6_v1.mp3`} className="hidden-audio" id="intr" ref={intr} />
        <audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
        <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />

        <div style={props.questions.style === "row" ? { display: "flex", flexDirection: "row", flexWrap: "wrap" } : {}}>

            {data.mainTitle !== undefined &&
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                    <div className="whatsname" dangerouslySetInnerHTML={{ __html: data.mainTitle }}></div>
                </div>
            }

            {data.speechs.map((item, m) => {

                let height_style = {}
                if (props.questions.height !== undefined) {
                    height_style = { minHeight: "auto" }
                }
                if (props.questions.minHeight !== undefined) {
                    height_style = { minHeight: props.questions.minHeight }
                }
                if (props.questions.style === "row") {
                    height_style.width = "40%";
                }
                if (props.questions.dataStyle === "row") {
                    height_style.display = "flex";

                }
                return (<div className="book-container v2" style={{ position: "relative", marginBottom: 30, ...height_style }}>
                    {item.mainTitle !== undefined &&
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                            <div className="whatsname">{item.mainTitle}</div>
                        </div>
                    }

                    {item.imageLink !== undefined && <img src={`${baseurl}${item.imageLink}`} className="image-style img-container" style={{ position: "static", height: "30vh" }} />}

                    {item.locations.map((subItem, i) => {

                        let rowStyle = {};
                        if (props.questions.showLines === true) {
                            rowStyle.borderBottom = "1px solid #D0D3E1";
                        }

                        let flexStyle = {};
                        let flexItem = {};
                        if (subItem.imagesBottomFlexWrap === true) {
                            flexStyle = { flexWrap: "wrap" }
                            flexItem = { marginBottom: 20 }
                        }

                        return (<><div style={{ top: subItem.top, width: "100%", display: "flex" }} className={subItem.location}>

                            {subItem.imagesLeft !== undefined && <div style={{ width: subItem.imagesLeftWidth, marginRight: 20 }}>
                                {subItem.imagesLeft.map((imageItem) =>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                                        <img src={baseurl + imageItem.url} />
                                        {imageItem.title !== undefined && <div style={{ background: "#22CBF2", color: "#fff", padding: "10px", fontFamily: "gotham-medium", borderRadius: 20, padding: "10px 20px", marginTop: -15 }}>{imageItem.title}</div>}
                                    </div>)}</div>
                            }

                            {subItem.location === "leftside" ?
                                <ArrowLeftSide className="leftiside-arrow" style={{ position: "absolute", right: -19 }} color="#22CBF2" /> :
                                <ArrowLeftSide className="rightside-arrow" color="#22CBF2" />}
                            <div style={{ flex: 1 }}>
                                {subItem.lines.map((lineItem, o) => {
                                    return (<div style={rowStyle}>
                                        {lineItem.spans.map((subItemV2, j) => {
                                            return (<span id={`item-${m}-${i}-${o}-${j}`} dangerouslySetInnerHTML={{ __html: subItemV2.text + " " }}></span>)
                                        })}
                                    </div>)


                                })}
                            </div>




                        </div>

                            {subItem.imagesBottom !== undefined && <div style={{ marginLeft: 20, display: "flex", marginTop: 20, alignItems: "center", justifyContent: "center", ...flexStyle }}>
                                {subItem.imagesBottom.map((imageItem) => <div style={{ marginRight: 20, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", ...flexItem }}><img src={baseurl + imageItem.url} />
                                    {imageItem.title !== undefined && <div style={{ background: "#22CBF2", color: "#fff", padding: "10px", fontFamily: "gotham-medium", borderRadius: 20, padding: "10px 20px", marginTop: -15 }}>{imageItem.title}</div>}

                                </div>)}</div>
                            }
                        </>)
                    })}


                </div>)
            })}
        </div>
        <audio src={baseurl + data.audio} ref={audioPlayer} />

        {/* <div align="center" style={{position:"relative"}}>

               

                  
                <img src={`${baseurl}${data[0].imageLink}`} className="image-style" />
                {data[0].locations.map((item,i) => {

                
                    return(<div className={item.location}>{item.spans.map((subItem,j) => {
                        return(<span id={`item-${i}-${j}`} dangerouslySetInnerHTML={{__html: subItem.text + " "}}></span>)
                    })}
                                        
                    </div>)
                })}
                                        <audio src={baseurl + data[0].audio} ref={audioPlayer} />

                </div> */}




        <div className="button-check">
            <button className="check-answer-bt" variant="contained" color="primary" onClick={checkQuestion}>Next</button>

        </div>


    </div>)
}
export default DragAndDrop;