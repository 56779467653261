import react,{useEffect, useState } from 'react'
import axios from 'axios';
import _, { forEach } from 'lodash';
import { useHistory } from "react-router-dom";
import TickIcon from './../components/svgs/tick'
import CrossIcon from './../components/svgs/cross'
import ReactHlsPlayer from 'react-hls-player';

let words = ["Sit down","Stand up","Write"]
const baseurl = process.env.REACT_APP_FRONT_LINK;

const audioStyle=  {
    background:"#0B329D",width:30,height:30,display:"inline-block",alignItems:"center",justifyContent:"center",borderRadius:"100%",padding:10,marginLeft:10,cursor:"pointer"
}
function DragAndDrop(props) {
    
    const history = useHistory();
    const [currentDrag,setCurrentDrag] = useState({});
    const [images,setImages] = useState([]);
    const [videoLink, setVideoLink] = useState("");

    const [userSelect,setUserSelect] = useState([
    
    ]);

    useEffect(() => {

        if(props.questions.videoCode !== undefined) {

            fetch("https://api.vimeo.com/videos/" + props.questions.videoCode,{
                method: "GET",
                headers: {
                    "Authorization": "Bearer aeeec5987db81bbfd76e0f272e35468c", 
                }
            }).then(response => response.json()).then(data => {
                // console.log(data);
                // setVideoData(data.embed.html)
                // console.log("video212",{
                //     autoplay: true,
                //     controls: true,
                //     responsive: true,
                //     fluid: true,
                //     sources: [{
                //       src: data.files[0].link,
                //       type: 'video/mp4'
                //     }]
                // })

                
                if(data.files !== undefined) {
                    let files = data.files;
                    let findHls = files.findIndex((item) => item.quality === "hls");

                    if(findHls !== -1) {
                        setVideoLink(data.files[findHls].link_secure);
                    }
                }
            
            });

        }

    },[props])


    const [items,setItems] = useState([]);

        const [randList,setRandList] = useState([]);
        const [randListV1,setRandListV1] = useState([]);

        const checkQuestion = () => {

            let answerFormat = {
                pass:true,
                progress:0,
                questionTitle:props.questions.title,
                userAnswers:[],
                type:"completeRev_lines"
            }
            
            
            let items2 = [...randListV1];
            let allcorrect = true;



            items2.forEach((item,m) => {
                item.questions.forEach((questionItem,i) => {

                    questionItem.lines.forEach((lineItem,j) => {
                        lineItem.spans.map((spanItem,j) => {
                            // console.log("item.questions",spanItem)

                            if(spanItem.type === "input") {
                                spanItem.userAnswer = spanItem.userAnswer.replaceAll("’","'").trim();
                                spanItem.text = spanItem.text.replaceAll("’","'").trim();

                                
                                // if(spanItem.keywords !== undefined) {
                                //     spanItem.userAnswer = spanItem.userAnswer.replaceAll("’","'").trim();
                                //     spanItem.userAnswer = spanItem.userAnswer.replaceAll("“","'").trim();
                                //     spanItem.userAnswer = spanItem.userAnswer.replaceAll("”","'").trim();

                                //     let findKeywords = spanItem.keywords.findIndex((subkey) => {
                                //         return spanItem.userAnswer.search(subkey) !== -1
                                //     })

                                //     if(findKeywords === -1) {
                                //         items2[m].questions[i].correct = false;
                                //         allcorrect = false;
                                //     } else {
                                //         items2[m].questions[i].correct = true;
                                //     }
                                    
                                // }
                                if(spanItem.exists !== undefined) {
                                    let userAnswer44 = spanItem.userAnswer.toLowerCase();
                                    items2[m].questions[i].correct = false;
                                    spanItem.exists.forEach((sub) => {
                                        if(spanItem.userAnswer.search(sub) !== -1) {
                                            items2[m].questions[i].correct = true;
                                        }
                                    });

                                    if(items2[m].questions[i].correct === false) {
                                        allcorrect = false;
                                        answerFormat.pass = false;
                                    }
                                     // if(spanItem.exists.contains(userAnswer44) !== -1) {
                                    //     items2[m].questions[i].correct = true;
                                    // } else {
                                    //     allcorrect = false;
                                    //     items2[m].questions[i].correct = false;
                                    // }
                                }
                                else if(spanItem.possibleAnswer2 !== undefined) {

                                    let checkafterVariations = true;
                                    let userAnswer2 = spanItem.userAnswer;
                                    userAnswer2 = userAnswer2.toLowerCase();
                                    if(spanItem.afterVariations2 === undefined) {
                                        checkafterVariations = false;
                                    }

                                    let allcorrect2 = true;

                                    spanItem.possibleAnswer2.forEach((subItem) => {
                                        if(userAnswer2.search(subItem) === -1) {
                                            allcorrect2 = false;
                                        }
                                    });

                                    if(allcorrect2 === true) {
                                        items2[m].questions[i].correct = true;
                                    } else {
                                        items2[m].questions[i].correct = false;
                                        allcorrect = false;
                                    }


                                }
                                else if(spanItem.possibleAnswer !== undefined) {

                                    let checkafterVariations = true;



                                    let userAnswer2 = spanItem.userAnswer;


                                    if(spanItem.afterVariations !== undefined) {

                                        if(userAnswer2.search(spanItem.afterVariations) === -1) {
                                            checkafterVariations = false;
                                        }
                                        


                                        userAnswer2 = userAnswer2.replaceAll(spanItem.afterVariations,"");
                                    }
                                    
                                    userAnswer2 = userAnswer2.replaceAll(", and ",", ");

                                    userAnswer2 = userAnswer2.replaceAll(" and ",", ");

                                    let splited_list = userAnswer2.split(spanItem.explode);

                                    spanItem.possibleAnswer = spanItem.possibleAnswer.map((item) => item.toLowerCase());
                                    splited_list = splited_list.map((item) => item.toLowerCase());

                                    splited_list = splited_list.sort();

                                    spanItem.possibleAnswer = spanItem.possibleAnswer.sort();
                                    // console.log(splited_list,spanItem.possibleAnswer)

                                    console.log("www2",splited_list,spanItem.possibleAnswer);
                                    if(_.isEqual(splited_list,spanItem.possibleAnswer) && checkafterVariations === true) {
                                        
                                        items2[m].questions[i].correct = true;

                                    } else {


                                        spanItem.userAnswer = spanItem.userAnswer.replaceAll("’","'").trim();
                                        spanItem.userAnswer = spanItem.userAnswer.replaceAll("“","'").trim();
                                        spanItem.userAnswer = spanItem.userAnswer.replaceAll("”","'").trim();
                                        if(spanItem.answers === undefined) {
                                            spanItem.answers = []
                                        }
                                        if(spanItem.answers.length > 0) {
                                            spanItem.answers = spanItem.answers.map((item) => item.toLowerCase());
                                            spanItem.answers = spanItem.answers.map((item) => item.replaceAll("’","'"));
                                            spanItem.answers = spanItem.answers.map((item) => item.replaceAll("“","'"));
                                            spanItem.answers = spanItem.answers.map((item) => item.replaceAll("”","'"));
    
                                            if(spanItem.answers.indexOf(spanItem.userAnswer.toLowerCase()) === -1) {
                                                allcorrect = false; 
                                                answerFormat.pass = false;
                                                items2[m].questions[i].correct = false;
                                            } else {
                                                items2[m].questions[i].correct = true;
                                            }
                                        } else {
                                            allcorrect = false; 
                                            answerFormat.pass = false;
                                            items2[m].questions[i].correct = false;
                                        }

                                        // allcorrect = false;
                                        // items2[m].questions[i].correct = false;
                                    }
                                }
                                else if(spanItem.answers !== undefined) {

                                    spanItem.userAnswer = spanItem.userAnswer.replaceAll("’","'").trim();
                                    spanItem.userAnswer = spanItem.userAnswer.replaceAll("“","'").trim();
                                    spanItem.userAnswer = spanItem.userAnswer.replaceAll("”","'").trim();

                                    if(spanItem.answers.length > 0) {
                                        spanItem.answers = spanItem.answers.map((item) => item.toLowerCase());
                                        spanItem.answers = spanItem.answers.map((item) => item.replaceAll("’","'"));
                                        spanItem.answers = spanItem.answers.map((item) => item.replaceAll("“","'"));
                                        spanItem.answers = spanItem.answers.map((item) => item.replaceAll("”","'"));

                                        

                                        if(spanItem.answers.indexOf(spanItem.userAnswer.toLowerCase()) === -1) {
                                            if(spanItem.keywords !== undefined) {
                                                let findKeywords = spanItem.keywords.findIndex((subkey) => {
                                                    let find = spanItem.userAnswer.toLowerCase().search(subkey);

                                                    console.log("keyboards",spanItem.userAnswer.toLowerCase(),subkey,find);

                                                    return find !== -1
                                                });
            
                                                if(findKeywords === -1) {
                                                    items2[m].questions[i].correct = false;
                                                    allcorrect = false;
                                                } else {
                                                    items2[m].questions[i].correct = true;
                                                }
                                            } else {
                                                allcorrect = false; 
                                                items2[m].questions[i].correct = false;    
                                            }
                                            

                                        } else {
                                            items2[m].questions[i].correct = true;
                                        }
                                    }
                                }
    
                                else if(spanItem.fullAnswers !== undefined) {

                                    if(items2[m].questions[i].correct !== undefined) {
                                        if(spanItem.userAnswer !== "") {
                                            items2[m].questions[i].correct = true;
                                        } else {
                                            items2[m].questions[i].correct = false;
                                            allcorrect = false;
                                        }
                                    }
                                    
                                    
                                }
                                // else if(spanItem.answers !== undefined) {
                                //     items2[m].questions[i].correct = true;
                                // }
                                else if(spanItem.open === true && spanItem.userAnswer !== "") {
                                    items2[m].questions[i].correct = true;
                                }
                                else if(spanItem.userAnswer.toLowerCase() !== spanItem.text.toLowerCase()) {
    
    
    
    
                                    // console.log(questionItem.spans,spanItem.userAnswer.toLowerCase(),spanItem.text.toLowerCase())
    
                                    // console.log(allcorrect,spanItem.userAnswer.toLowerCase(),spanItem.text.toLowerCase())
                                    allcorrect = false;
                                    items2[m].questions[i].correct = false;
                                } else {
                                    items2[m].questions[i].correct = true;
                                }

                                
                            }
    
                        });
                    });



                    return;

                    // questionItem.spans.map((spanItem,j) => {
                    //     // console.log("item.questions",spanItem)

                    //     if(spanItem.type === "input") {
                    //         spanItem.userAnswer = spanItem.userAnswer.replaceAll("’","'").trim();
                    //         spanItem.text = spanItem.text.replaceAll("’","'").trim();

                    //         if(spanItem.answers !== undefined) {
                    //             if(spanItem.answers.length > 0) {
                    //                 spanItem.answers = spanItem.answers.map((item) => item.toLowerCase());

                    //                 if(spanItem.answers.indexOf(spanItem.userAnswer.toLowerCase()) === -1) {
                    //                     allcorrect = false;
                    //                     items2[m].questions[i].correct = false;
                    //                 } else {
                    //                     items2[m].questions[i].correct = true;
                    //                 }
                    //             }
                    //         }

                    //         else if(spanItem.fullAnswers !== undefined) {
                    //             items2[m].questions[i].correct = true;
                    //         }
                    //         else if(spanItem.answers !== undefined) {
                    //             items2[m].questions[i].correct = true;
                    //         }
                    //         else if(spanItem.open === true) {
                    //             items2[m].questions[i].correct = true;
                    //         }
                    //         else if(spanItem.userAnswer.toLowerCase() !== spanItem.text.toLowerCase()) {




                    //             // console.log(questionItem.spans,spanItem.userAnswer.toLowerCase(),spanItem.text.toLowerCase())

                    //             // console.log(allcorrect,spanItem.userAnswer.toLowerCase(),spanItem.text.toLowerCase())
                    //             allcorrect = false;
                    //             items2[m].questions[i].correct = false;
                    //         } else {
                    //             items2[m].questions[i].correct = true;
                    //         }
                    //     }

                    // });
                });

                item.answers.forEach((questionItem,i) => {
                    questionItem.spans.map((spanItem,j) => {    
                        // console.log("item.answers",spanItem)

                        if(spanItem.answers !== undefined) {

                            let checksub = false
                            spanItem.answers.forEach((subItem2) => {
                                if(spanItem.userAnswer.toLowerCase() === subItem2.toLowerCase()) {
                                    checksub = true;
                                }
                            });

                            if(checksub === true) {
                                items2[m].questions[i].correct = true;
                            } else {
                                allcorrect = false;
                                items2[m].questions[i].correct = false;
                            }




                        }
                        else if(spanItem.type === "input") {
                            

                            if(spanItem.userAnswers !== undefined) {
                                console.log(spanItem.userAnswers.indexOf(spanItem.userAnswer),allcorrect);
                                if(spanItem.userAnswers.indexOf(spanItem.userAnswer) !== -1) {
                                    items2[m].questions[i].correct = true;
                                } else {
                                    allcorrect = false;
                                    items2[m].questions[i].correct = false;
                                }
                            }
                            else if(spanItem.open === true) {
                                items2[m].questions[i].correct = true;
                            }
                            else if(spanItem.userAnswer.toLowerCase() !== spanItem.text.toLowerCase()) {
                                // console.log(allcorrect,spanItem.userAnswer.toLowerCase(),spanItem.text.toLowerCase())

                                allcorrect = false;
                                items2[m].questions[i].correct = false;
                            } else {
                                items2[m].questions[i].correct = true;
                            }
                        }
                    });
                });

            });


            answerFormat.pass = allcorrect;


            if(allcorrect === true) {
                document.getElementById("correct-au").play();

            } else {
                document.getElementById("wrong-au").play();

            }
            setRandListV1(items2);

            setTimeout(() => {

                
              props.nextExercise(answerFormat);


                // history.push(baseurl + "look_listen_say")
            }, 3000);
            
        }

    const shuffle = (array) => {
        let currentIndex = array.length,  randomIndex;
      
        // While there remain elements to shuffle...
        while (currentIndex != 0) {
      
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
      
          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
      
        return array;
      }
      
      const changeTxt = (value,i,j) => {
          let userSelect2 = [...userSelect];

          userSelect2[i].words[j] = value;
          setUserSelect(userSelect2);

        //   userSelect2[i] = value;
        //   setUserSelect(userSelect2);

      }

    useEffect(() => {
        setTimeout(() => {
            // document.getElementById("intr22").play();
        },300)
        let currentItems = [...items];


        if(props.questions.images !== undefined) {
            setImages(_.shuffle(props.questions.images));
        }

        // currentItems = shuffle(currentItems);

        setRandList(currentItems);
        document.getElementById("intr22").play();

        setRandListV1(props.questions.answers)

        return () => {

            
            setImages([]);
        }

    }, [props])

    
    const playIntr = (e) => {
        document.getElementById("intr22").play();
    }

    const checkQuestionAnswer = (val,m,i,j,k) => {
        let randListV2 = [...randListV1];
        randListV2[m].questions[i].lines[j].spans[k].userAnswer = val;
        setRandListV1(randListV2);
    }

    const checkAnswer = (val,m,i,j) => {
        let randListV2 = [...randListV1];
        randListV2[m].answers[i].spans[j].userAnswer = val;
        setRandListV1(randListV2);

    }
    const videoPlay = (e) => {
        document.getElementById("video-lin").play();
        document.getElementById("video-waf").style.display = "none";
    }

    let minWidth = "60vw";

    let itemWidth = 120;

    if(props.questions.minWidthLeft !== undefined) {
        minWidth = props.questions.minWidthLeft;
    }
    if(props.questions.minWidthRight !== undefined) {
        itemWidth = props.questions.minWidthRight;
    }
    let imageWidth = 180;

    if(props.questions.maxWidth !== undefined) {
        imageWidth = props.questions.maxWidth;
    }
    return(<div id="complete_write" class="complete_multilines" style={{width:props.questions.width}}>
        {console.log("props.questions.width",props.questions)}
                <div className="question-title-container">
                <div style={{display:"flex",alignItems:"center",gap:"20px"}}>
                <img src={baseurl + "assets/game5/write.svg"} />

                    <h1 className="question-title" dangerouslySetInnerHTML={{ __html: props.questions.title }}></h1>
                </div>
                <div style={{display:"flex",alignItems:"center"}}>
                {props.questions.unitNo !== undefined && <div class="unit-color"><span>{props.questions.unitNo}</span></div>}

                <div id="audio-bt" onClick={playIntr}><img src={baseurl + "assets/audio/listen.png"} />

</div>


                </div>
                </div> 

                {props.questions.tableboxes !== undefined && <div id='tableboxes-container'>
                    {props.questions.tableboxes.map((item) => {
                        return(<div className='tableboxes-item'>
                            <div className='tableboxes-title'>{item.title}</div>
                            <div className='tableboxes-subitem-container'>
                            {item.tableData.map((subItem) => {
                                return(<div className='tableboxes-subitem'>
                                    {subItem.body.map((bodyItem) => <div dangerouslySetInnerHTML={{__html:bodyItem}}></div>)}

                                </div>)
                            })}
                            </div>
                            
                        </div>)
                    })}
                </div>} 

                {videoLink !== "" &&
         <div className="video-intro" style={{maxWidth:"60vw",minWidth:"60vw",marginBottom:50}}>
          <ReactHlsPlayer
          src={videoLink}
          autoPlay={false}
          controls={true}
          width="100%"
          height="auto"
        /></div>}
                {props.questions.video !== "" && <div style={{maxWidth:"60vw",minWidth:"60vw",marginBottom:50}}>

            

                
                <div class="video-intro" style={{height: "50vh",width:"100%"}}><img onClick={videoPlay} src={baseurl + "assets/game1/play.png"} id="video-waf" /><video style={{width:"100%"}} id="video-lin" src={baseurl + props.questions.video} controls></video></div>
                </div>}

                {props.questions.muzzyTitle !== undefined && <div style={{maxWidth:"60vw",display:"flex",alignItems:"center",gap:20,marginBottom:30}}><img src={baseurl + props.questions.muzzyTitle} /></div>}

                {props.questions.image !== "" && <div style={{maxWidth:"60vw",display:"flex",alignItems:"center",justifyContent:"center",gap:20,marginBottom:30}}><img src={baseurl + props.questions.image} /></div>}
                

                {props.questions.journalBox !== undefined && <div className='journalbox-container'><div className='journalbox'>
                    <div className="journalbox-title">{props.questions.journalBox.title}</div>
                    <div className="journalbox-body" dangerouslySetInnerHTML={{__html:props.questions.journalBox.body}}></div>
                    <div class='journalbox-top-left'><img src={baseurl + "assets/levels/level5/unit1/watch_video/ex4/top_left.png"} /></div>
                    <div class='journalbox-bottom-right'><img src={baseurl + "assets/levels/level5/unit1/watch_video/ex4/bottom_right.png"} /></div>
                    <div class='journalbox-bottom-left'><img src={baseurl + "assets/levels/level5/unit1/watch_video/ex4/bottom_left.png"} /></div>

                </div></div>}

                {props.questions.options !== undefined && <div style={{background:"#22cbf2",marginBottom:30,display:"flex",alignItems:"center",justifyContent:"center",padding:"30px 0px",flexWrap:"wrap"}}>
                    {props.questions.options.map((item) => <div style={{padding:"5px 20px",fontFamily:"gotham-medium",fontSize:25}}>{item}</div>)}

                </div>}
                <div style={{display:"flex",alignItems:"flex-start"}}>
               
                
        <div id="complete-items-v1" style={{minWidth:minWidth,flex:1}}>

            {randListV1.map((item,m) => {
                // console.log("item_v1",item);
                return(<div>
                    {item.questions.map((questionItem,i) => {

                        let rowStyle = {}
                        if(questionItem.image !== undefined) {
                            rowStyle = {flexDirection:"row",alignItems:"center",justifyContent:"space-between",width:"100%"};
                        }
                        if(props.questions.removeBackground !== undefined) {
                            rowStyle = {background:"none"}
                        }
                       
                        return(<>{questionItem.title !== undefined && <h2 style={{marginTop:50,color:"#000",fontFamily:"gotham-bold",color:"#0B329D",fontWeight:"normal"}}>{questionItem.title}</h2>}<div className="items-v1" style={{marginBottom:20,display:"flex",flexDirection:"column",boxSizing:"border-box",position:"relative",...rowStyle}}>
                            <div>{questionItem.lines.map((lineItem,j) => {

                                

                                return(<div style={{padding:"20px 0px",lineHeight:"55px"}}>
                                {lineItem.spans.map((spanItem,k) => {
                                    if(spanItem.type === "input") {
                                        let size = spanItem.text.length;

                                        if(props.questions.size !== undefined) {
                                            size = props.questions.size;
                                        }

                                        if(spanItem.size !== undefined) {
                                            size = spanItem.size;
                                        }
                                        let border = {};
                                        if(spanItem.border === true) {
                                            border = {border:"3px dashed #1BAFDB",borderRadius:10}
                                        }

                                        if(props.questions.fontSize !== undefined) {
                                            border.fontSize = props.questions.fontSize;
                                        }
                                        return(<span><input spellCheck="false"  type='text' style={{width:"auto",textAlign:"left",...border}} 
                                        value={spanItem.userAnswer} onChange={(e) => { checkQuestionAnswer(e.target.value,m,i,j,k) }} size={size} maxLength={size} />
                                        {/* {lineItem.audio !== undefined && <>
                                            <div style={{...audioStyle,right:60,top:30,lineHeight:"37px"}} onClick={(e) => document.getElementById("audioitem-" + m + i + j + k).play()}>
                                <img src={baseurl + "assets/audio/listen.png"} />
                                </div>
                                <audio src={baseurl + lineItem.audio} id={"audioitem-" + m + i + j + k} />
                                        </>} */}
                     


                                        </span>)
                                    } else {

                                        let spanStyle = {};
                                        if(spanItem.text === ".") {
                                            spanStyle = {position:"relative",bottom:-8};
                                        }

                                        let arrowStyle = {};
                                        if(spanItem.arrowlocation === "rightBottom") {
                                            arrowStyle.transform = "rotate(-45deg)";
                                        }
                                        if(spanItem.arrowlocation === "rightTop") {
                                            arrowStyle.transform = "rotate(-135deg)";
                                        }

                                        if(spanItem.arrowEnd === true) {
                                            arrowStyle.right = 10;
                                            arrowStyle.left = "auto";
                                        }
                                        
                                        return(
                                        <>
                                        <span style={{margin:0,position:"relative"}}>

                                       {spanItem.arrow === true && 
                                       
                                       <svg xmlns="http://www.w3.org/2000/svg" height={30} width={20} viewBox={"0 0 370 433"} style={{position:"absolute",left:0,top:-30,
                                        ...arrowStyle
                                    }}>
    <path
      d="M96.728 342.94 7.48 253.69V196.94c0-31.212.34-56.748.753-56.748.414 0 30.45 29.7 66.747 66 37.024 37.027 66.763 66 67.744 66 1.656 0 1.763-7.156 2.003-133.75l.253-133.75h84l.254 133.25c.24 126.118.347 133.25 2.003 133.25.981 0 30.505-28.759 67.243-65.5 36.022-36.025 65.833-65.5 66.248-65.5.414 0 .752 25.531.752 56.737v56.736l-89.242 89.264c-49.083 49.094-89.472 89.263-89.752 89.263-.28 0-40.671-40.164-89.758-89.252z"
      style={{
        fill: "rgb(34, 203, 242)",
      }}
    />
  </svg>
                                       } 

  <span style={spanStyle} dangerouslySetInnerHTML={{__html:spanItem.text}}></span>
  </span>
                                        
                                        {/* {lineItem.audio !== undefined && <>
                                            <div style={{...audioStyle,right:60,top:30,lineHeight:"37px"}} onClick={(e) => document.getElementById("audioitem-" + m + i + j + k).play()}>
                                <img src={baseurl + "assets/audio/listen.png"} />
                                </div>
                                <audio src={baseurl + lineItem.audio} id={"audioitem-" + m + i + j + k} />
                                        </>} */}
                                        </>
                                        )
                                    }
                                })}

                                {lineItem.audio !== undefined && <>
                                            <div style={{...audioStyle,right:60,top:30,lineHeight:"37px"}} onClick={(e) => document.getElementById("audioitem-" + m + i + j).play()}>
                                <img src={baseurl + "assets/audio/listen.png"} />
                                </div>
                                <audio src={baseurl + lineItem.audio} id={"audioitem-" + m + i + j} />
                                        </>}
                                
                                </div>)
                                

                            })}

                            {questionItem.audio !== undefined &&
                            <>
                            <audio src={baseurl + questionItem.audio} id={"audioitem-" + i} />
                                                                <div style={{...audioStyle,position:"absolute",right:60,top:30}} onClick={(e) => document.getElementById("audioitem-" + i).play()}>
                                <img src={baseurl + "assets/audio/listen.png"} />
                                </div>
                            </>
                            }
                                

                            </div>
                            

                            {questionItem.image !== undefined && <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",marginRight:90}}><img style={{maxWidth:imageWidth,height:"auto"}} src={baseurl + questionItem.image} />
                            {questionItem.imageTitle !== undefined &&
                            <div style={{display:"flex",justifyContent:"center",alignItems:"center",fontSize:17,background:"#22CBF2",padding:10,borderRadius:20,marginTop:10,color:"#fff"}}>
                            {questionItem.imageTitle}
                        </div>
                            }
                            
                            </div>}

                            {props.questions.showAnswers === true &&
                            <div style={{margin:"20px 0px 0px"}}>{item.answers[i].spans.map((spanItem,j) => {

                                if(spanItem.type === "input") {
                                    return(<span><input spellCheck="false"  type='text' style={{width:"auto",textAlign:"left"}} onChange={(e) => { checkAnswer(e.target.value,m,i,j) }} size={spanItem.text.length} maxLength={spanItem.text.length} /></span>)
                                } else {
                                    return(<span>{spanItem.text}</span>)
                                }

                            })}</div>
                            }
                            
                            {questionItem.correct !== undefined && 
                            <>
                            {questionItem.correct === true &&  <div style={{position:"absolute",right:25,top:30}}><TickIcon /></div>}
                            {questionItem.correct === false &&  <div style={{position:"absolute",right:25,top:30}}><CrossIcon /></div>}


                            </>
                            }


                            
                            </div></>)
                    })}
                </div>)
            })}

    {props.questions.bottomimages !== undefined && <div style={{display:"flex",flexWrap:"wrap",gap:20}}>
        {props.questions.bottomimages.map((item) => {
            return(<div><img style={{borderRadius:20,width:props.questions.minWidthBottom}} src={baseurl + item.url} /></div>)
        })}
    </div>}



            {/* {randList.map((item,i) => {
                return(<div className="items-v1">{item.words.map((subItem,j) => {
                    if(subItem === "") {
                        let char_len= item.word.split(" ");
                        
                        return <span><input spellCheck="false"  type="text" onChange={(e) => {changeTxt(e.target.value,i,j)}} maxLength={char_len[j] !== undefined?(char_len[j].length>2?1:2):1} /></span>

                    } else {
                        return <span>{subItem + " "}</span>
                    }
                })}</div>);
            })} */}
        </div>


        {images.length > 0 && 
                    <div style={{maxWidth:"60vw",flexDirection:"column",marginLeft:20,display:"flex",alignItems:"center",justifyContent:"center",gap:20,marginBottom:30,padding:"0px 0px",width:itemWidth}}>
                        {images.map((item) => <div style={{borderRadius:20,overflow:"hidden"}}>
                            <img src={baseurl + item.url} />
                        </div> )}
                </div>}

        </div>



            <audio src={`${baseurl}${props.questions.audio}`} className="hidden-audio" id="intr22" />
            <audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
            <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />

            <div className="button-check">
                <button className="check-answer-bt" variant="contained" color="primary" onClick={checkQuestion}>{props.questions.buttonText !== undefined?props.questions.buttonText:"Check my answer"}</button>
            </div>


    </div>)
}
export default DragAndDrop;